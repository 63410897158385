.messageList {
    margin: 17px auto 0;
    width: 1170px;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-title {
        margin: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        color: #000000;
    }

    &-filterIconButton {
        margin-left: 20px;
        width: 70px;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(44, 97, 154, 0.05);
        border-radius: 5px;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }

        &:active {
            outline: none;
        }
    }

    &-body {
        margin: 17px auto 0;
        width: 1170px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(44, 97, 154, 0.05);
        border-radius: 15px;
        padding: 20px 37px 0;
    }

    &-bodyHeader {
        margin: 0 auto;
        padding: 10px 15px;
        width: 1084px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: rgba(196, 196, 196, 0.2);
        border-radius: 5px;

        span {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
        }

    }

    &-iBox {
        width: 1084px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #000000;

        &-item {
            padding-left: 13px;
            height: 74px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;

            &-name {
                margin: 15px 0;
                width: 222px;

                h5 {
                    margin: 5px 0 0;
                    font-family: Roboto, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: -0.24px;
                    color: #82899C;
                }

            }

            &-nameInfo {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                p {
                    margin: 0;
                    font-family: Roboto, sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 22px;
                    letter-spacing: 0.35px;
                    color: #000004;
                }
            }
        }
    }

    &-navigation {
        display: block;
        width: 1084px;
        margin: 0 auto;
        position: relative;
        padding: 0 0 1px;


        &__pages {
            margin: 25px auto 15px;
        }

        &__perPage {
            position: absolute;
            top: -7px;
            right: 0;
            width: 300px;
        }
    }

    .MuiSelect-icon {
        top: 1px!important;
        color: #B6C0CB;
    }
}

.MuiOutlinedInput-root {
    height: 60px !important;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: transparent !important;
    color: #3371DE;
}

.MuiTablePagination-actions {
    display: none;
}

p.MuiTablePagination-caption:nth-child(4) {
    display: none;
}

.MuiTablePagination-selectRoot {
    margin-right: 0 !important;
}

#root > div.messageList > div.messageList-body > div.messageList-navigation > div.messageList-navigation__pages > nav > ul > li:nth-child(9) > button,
#root > div.messageList > div.messageList-body > div.messageList-navigation > div.messageList-navigation__pages > nav > ul > li:nth-child(1) > button {
    background-color: #F4F5F6 !important;
    width: 32px;
    height: 32px;
}

.MuiPagination-ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MuiPaginationItem-root {
    min-width: 0;
}

.MuiTablePagination-caption {
    color: #5F6871!important;
}

.MuiTablePagination-input {
    border: 1px solid rgba(95, 104, 113, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    background: #F4F5F6;
}