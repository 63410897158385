.privacy {
    max-width: 1360px;
    margin: 0 auto;
    text-align: justify;

    @media (min-width: 0) and (max-width: 1360px) {
        padding: 0 15px;
    }

    &-title {
        margin-top: 50px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #171717;
    }

    &-subTitle {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #171717;
    }

    &-attention {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;;
        text-align: center;
    }
}