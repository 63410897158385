/**
 *
 * Basic Formatting
 *
 **/

html {
    box-sizing: border-box;
    font-size: $base-fontsize;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html {
        font-size: $base-fontsize;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box
}

[hidden] {
    display: none !important;
}


/**
 * Body
 */

body {
    overflow-y: scroll;
    margin: 0;
    height: 100vh;
    font-family: $base-font;
    font-size: $base-fontsize;
    font-weight: 400;
    line-height: $base-lineHeight;
    text-align: left;
    //text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.admin {
    background: #f8f8f8;
}

body.no-scrollable {
    overflow: hidden;
}

#root {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headline-font;
    font-weight: 500;
    color: #1e2c4f;
}

h1 {
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    line-height: initial;
}

h2 {
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    line-height: initial;
}

h3 {
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: initial;
}

h4 {
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    line-height: initial;
}

h5 {
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    line-height: initial;
}

h6 {
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    line-height: initial;
}

/**
 * General Text Formattings
 */

b,
strong {
    font-weight: 700;
}

i,
em,
dfn {
    font-style: italic;
}

address {
    font-style: normal;
}

abbr {
    font-variant: small-caps;
    letter-spacing: 1px;
    cursor: help;
}

sub,
sup {
    position: relative;
    font-size: 10px;
    line-height: 10px;
}

sub {
    top: 4px;
}

sup {
    top: -6px;
}

q,
cite,
blockquote {
    font-family: $base-font;
    font-style: italic;
}

hr {
    height: 1px;
    margin: 0;
    border: 0;
    padding: 0;
}

p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    line-height: 2em;
}

.title {
    margin-top: 50px;
    text-align: center;
}

/**
 * Images
 */

img {
    display: block;
    //max-width: 100%;s
}


/**
 * Buttons
 */

button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

button {
    overflow: visible;
    appearance: none;
    margin: 0;
    border: 0;
    border-radius: 0;
    padding: 0;
    text-transform: none;
    background-color: transparent;
}


.button-blue {
    cursor: pointer;
    display: flex;
    width: fit-content;
    height: 50px;
    text-decoration: none;
    background: #1890FF;
    box-shadow: 0 6px 15px rgba(44, 97, 154, 0.2);
    border-radius: 100px;
    transition: 0.3s;
    outline: none;
    padding: 0 30px;

    span {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        flex: none;
        order: 0;
        align-self: center;
        margin: 0 auto;
        color: #fff;
    }

    &:hover {
        background-color: #127DDE;
        transition: 0.3s;
    }
}

.button-grey {
    cursor: pointer;
    display: flex;
    width: fit-content;
    height: 50px;
    text-decoration: none;
    background: #DDDFE5;
    border-radius: 50px;
    transition: 0.3s;
    outline: none;
    padding: 0 30px;
    align-items: center;

    img {
        margin-right: 10px;
    }

    span {
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 17px;
        flex: none;
        order: 0;
        align-self: center;
        margin: 0 auto;
        color: #333333;
    }

    &:hover {
        background-color: #9F9F9F;
        transition: 0.3s;
    }
}

.link {
    color: #1890FF;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    &:hover {
        color: #127DDE;
    }

    img {
        margin-right: 10px;
        width: 18px;
        height: 14px;
    }
}

.center {
    margin: 20px auto;
    text-align: center;
}



.MuiButtonBase-root + .MuiTab-root + .Mui-selected {
    background: #FFFFFF;
    border-radius: 8px 8px 0 0;
    opacity: 1;
}

.MuiTab-textColorInherit.Mui-selected {
    background: #FFFFFF !important;
    border-radius: 8px 8px 0 0 !important;
}

.MuiTabs-indicator {
    display: none !important;
}

.MuiButtonBase-root + .MuiTab-root {
    background: #E7E9EF;
    opacity: 1;
}

.MuiTab-root {
    width: calc(100% / 3);
}

.MuiAppBar-colorDefault {
    background: transparent !important;
}

.MuiAppBar-root {
    box-shadow: none;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.MuiBox-root {
    background: #FFFFFF;
    border-radius: 0 8px 8px 8px;
}

.advancedSearch .MuiTouchRipple-root {
    display: none;
}
