@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('../static/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('../static/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
    url('../static/fonts/gilroy/Gilroy-Medium.ttf') format('truetype'),
    url('../static/fonts/gilroy/Gilroy-Medium.eot') format('eot');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('../static/fonts/gilroy/Gilroy-Semibold.woff2') format('woff2'),
    url('../static/fonts/gilroy/Gilroy-Semibold.woff') format('woff'),
    url('../static/fonts/gilroy/Gilroy-Semibold.ttf') format('truetype'),
    url('../static/fonts/gilroy/Gilroy-Semibold.eot') format('eot');
    font-weight: 900;
    font-style: normal;
}
