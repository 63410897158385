.merchantHero {
    width: 100%;
    height: auto;
    background: url("../../../static/img/forMerchants/merchantMask.png") no-repeat center, linear-gradient(122.08deg, #1890FF 0%, #09A9EE 100%);
    background-size: cover;

    &-container {
        max-width: 1360px;
        margin: 26px auto 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media all and (max-width: 1360px) {
            flex-direction: column-reverse;
            align-items: center;
            padding: 0 15px;
        }

        &__image {
            img {
                margin: 0 auto;
                width: 100%;
                @media all and (max-width: 768px) {
                    width: calc(100% - 30px);
                }
            }
        }

        &__content {
            margin-top: 96px;
            margin-left: 40px;
            max-width: 600px;

            @media all and (max-width: 768px) {
                margin: 0;
            }
        }

        &__title {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 59px;
            color: #FFFFFF;

            @media all and (max-width: 768px) {
                text-align: center;
                font-size: 38px;
                line-height: 45px;
            }
        }

        &__text {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #F4F4F4;

            @media all and (max-width: 768px) {
                text-align: center;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.merchantContent {
    max-width: 1092px;
    margin: 0 auto;


    &-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

    }

    &-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 70px;

        &:last-child {
            margin-bottom: 0;
        }

        @media all and (max-width: 768px) {
            margin-bottom: 50px;
        }
    }

    &-image {
        @media all and (max-width: 768px) {
            margin: 0 auto;
        }

        img {

            @media all and (max-width: 768px) {
                width: calc(100% - 30px);
                margin: 0 auto;
            }
        }
    }

    &-contentBox {
        max-width: 600px;
        margin-left: 92px;


        @media all and (max-width: 768px) {
            width: 100%;
            margin: 0 auto;
            padding: 0 15px;
        }

    }

    &-title {
        max-width: 600px;
        margin: 120px auto 50px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 59px;
        text-align: center;
        color: #171717;

        @media all and (max-width: 768px) {
            font-size: 38px;
            line-height: 45px;
            padding: 0 15px;
        }
    }

    &-subtitle {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        color: #1890FF;
        margin-bottom: 20px;

        @media all and (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &-list {
        list-style-type: none;
    }

    &-listItem {

        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #82899C;
        margin-bottom: 15px;

        @media all and (max-width: 768px) {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
        }

        &:before {
            content: '\2022';
            display: inline-block;
            font-weight: 700;;
            color: #1890FF;
            width: 12px;
            margin-right: 15px;

            @media all and (max-width: 768px) {
                margin-right: 12px;
            }
        }
    }
}

.tryIt {
    max-width: 600px;
    width: 100%;
    margin: 120px auto 0;

    &-title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 59px;
        text-align: center;
        color: #171717;
        margin-top: 0;
        margin-bottom: 30px;

        @media all and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }

    &-text {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #82899C;
        margin-bottom: 10px;
    }

    @media all and (max-width: 768px) {
        margin-top: 100px;
        padding: 0 15px;
    }
}