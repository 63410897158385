.subscribe {
    margin: 100px auto 0;

    width: 1140px;
    height: auto;
    background: url("../../../static/img/MaskSubscribe.png") no-repeat center, linear-gradient(125.18deg, #1890FF 0%, #09A9EE 100%);
    border-radius: 30px;
    background-size: cover;

    @media all and (min-width: 320px) and (max-width: 1172px) {
        max-width: 1140px !important;
        width: auto;
        margin: 100px 15px 0;
    }

    &-cover {
        padding: 50px 0;
        position: relative;
        z-index: 2;

        @media all and (min-width: 320px) and (max-width: 500px) {
            margin: 0 15px;
        }
        @media (min-width: 320px) and (max-width: 868px) {
            padding: 50px 15px !important;
        }
    }

    &-title {
        max-width: 700px;
        margin: 0 auto;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 700;;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        color: #FFFFFF;

        @media all and (min-width: 320px) and (max-width: 500px) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    &-form {
        max-width: 800px;
        margin: 0 auto;
        color: #F4F5F6;
        display: flex;
        flex-direction: column;


        &__input {
            margin: 30px auto !important;
            width: 100%;
            height: 60px;
            border: 2px solid #ffffff !important;
            background: transparent;
            border-radius: 70px;
            padding-left: 40px !important;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            outline: none;
            color: #F4F5F6 !important;

            @media all and (max-width: 575px) {
                margin: 30px auto 10px !important;
                padding-left: 20px !important;
            }

            &::placeholder {
                color: #F4F5F6;
                font-family: Roboto, sans-serif;
            }
        }

        &__button {
            margin-top: -2px !important;
            margin-right: -16px !important;
            width: 186px;
            height: 60px;
            border: none !important;
            background: #000004 !important;
            border-radius: 70px !important;
            font-family: Roboto, sans-serif;
            text-transform: inherit !important;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            @media all and (max-width: 575px) {
                width: 100%;
                margin-bottom: 25px !important;
            }
        }
    }

    &-success {
        width: 100%;
        margin: 30px auto 0;

        &__title {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 700;;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: #55F093;
            margin-bottom: 10px;

            @media all and (max-width: 768px) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        &__text {
            margin-top: 0;
            margin-bottom: 20px;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;

            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 15px;
            }
        }

        &__email {
            margin-top: 0;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            color: #000000;

            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

}

.subscribe-form .MuiFormControlLabel-label {
    font-size: 14px !important;
}

.subscribe-form .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.subscribe-form .MuiSvgIcon-fontSizeSmall {
    color: #DADADA !important;
}

.subscribe-form .MuiOutlinedInput-input {
    color: #FFFFFF !important;
    background-color: transparent !important;
    padding-left: 0 !important;

    &:-webkit-autofill {
        -webkit-text-fill-color: #FFFFFF !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
    }
}

.subscribe-form .MuiFormControlLabel-root {
    margin: 0 !important;
}

.subscribe-form .PrivateSwitchBase-root-5 {
    padding: 5px !important;
    @media all and (max-width: 575px) {
        padding-top: 0 !important;
    }
}

.subscribe-form .MuiFormControlLabel-label {
    @media all and (max-width: 575px) {
        padding-top: 10px !important;
    }
}

.subscribe-form .Mui-disabled {
    color: #fff!important;
}

.subscribe-form .MuiCheckbox-root {
    padding: 5px!important;
}