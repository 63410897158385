.header {
    z-index: 2;
    position: relative;
    height: 100px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    background: linear-gradient(167.87deg, #1890FF 0%, #09A9EE 100%);;

    @media (min-width: 320px) and (max-width: 768px) {
        height: 70px;
    }

    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        height: 70px;
    }

    &-nav {
        display: flex;
        flex-direction: row;

        .Mui-selected {
            background: transparent !important;
            color: #000004 !important;
        }

        &-item {
            list-style-type: none;
            align-self: center;
            font-family: Roboto, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #fff;

            &:nth-child(2) {
                margin-left: 50px;
            }
        }

        @media (min-width: 1001px) and (max-width: 1380px) {
            margin-right: 15px;
        }

        &__link {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            transition: 0.3s;

            &:hover, &:active, &:focus, {
                color: #000004;
                transition: 0.3s;
            }
        }

        @media all and (max-width: 1000px) {
            display: none;
        }

    }

    &-container {
        width: 1360px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        align-items: center;
    }

    &-logo {

        @media (min-width: 0) and (max-width: 1440px) {
            padding: 0 15px;
        }

        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        @media (min-width: 320px) and (max-width: 768px) {
            height: 50px;
            max-width: 1360px;
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        &_svg {
            width: 150px;


            @media (min-width: 320px) and (max-width: 768px) {
                width: 100px;
                margin: 10px 0;
                position: absolute;
                z-index: 99999999;
            }

            @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
                width: 100px;

            }

            &-menu {
                width: 150px;
                margin-top: -21px;
                position: absolute;
                z-index: 99999999;

                @media (min-width: 320px) and (max-width: 768px) {
                    width: 100px;
                    margin: 10px 0;
                    position: absolute;
                    z-index: 99999999;
                }
            }
        }
    }
}

.menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    list-style-type: none;

    @media all and (max-width: 1000px) {
        display: none;
    }

    &-item {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #131416;
        transition: 0.3s;

        &:hover, &:active, &:focus, {
            color: #000000;
            transition: 0.3s;
        }
    }
}

.side-drawer {
    height: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    background: url("../../../static/img/bg-mobile-menu.png") no-repeat center, linear-gradient(125.18deg, #1890FF 0%, #09A9EE 100%);
    background-size: cover;

    &-item {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        line-height: 23px;
        margin: 30px 30px 0;
        width: 100%;
        height: 30px;
        color: #ffffff;
        text-align: center;

        &:first-child {
            margin-top: 0;
        }
    }

    &-footer {
        width: 100%;
        text-align: center;
        bottom: 0;
        position: absolute;

    }

    &-footerMenu {
        display: flex;
        justify-content: space-evenly;

        &__item {
            color: rgba(225, 225, 225, 0.8);
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }

    &-footerCopy {
        color: rgba(225, 225, 225, 0.5);
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    &-line {
        position: absolute;
        top: 0;
        width: 100%;
        height: 70px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        @media all and (min-width: 768px) and  (max-width: 1000px) {
            height: 100px;
        }
    }
}

.drawer {
    display: none;
    color: white;

    @media (min-width: 320px) and (max-width: 1000px) {
        display: block;
        z-index: 99999;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    z-index: 100;

}

.BurgerActive > .BurgerBox > .BurgerInner {
    background-color: white;
}

.BurgerActive > .BurgerBox > .BurgerInner:after {
    background-color: white;
}

.BurgerActive > .BurgerBox > .BurgerInner:before {
    background-color: white;
}

.open {
    transform: translateX(0);
    transition: transform 0.3s ease-out;
}

.header .MuiListItem-root {
    width: auto !important;
}

.header-nav .MuiListItem-gutters {
    padding: 0 !important;
}

.selected {
    color: #000004;
}
