@import "./../../../scss/extends";
.advance {
    margin-top: 0!important;
}

.searchVenues {
    width: 100%;
    max-width: 700px;
    margin: 80px auto 0;
    padding: 0 15px;
    text-align: center;

    @media all and (max-width: 768px) {
        margin: 50px auto 0;
    }

    &-title {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 59px;
        text-align: center;
        color: #171717;
        margin-bottom: 20px;

        @media all and (max-width: 768px) {
            font-size: 38px;
            line-height: 45px;
        }
    }

    &-textBox {
        max-width: 600px;
        margin: 0 auto 60px;

        @media all and (max-width: 768px) {
            margin: 0 auto 40px;
        }
    }

    &-text {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #82899C;

        @media all and (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-formTitle {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        text-align: left;
        color: #171717;
        margin-bottom: 13px;

        @media all and (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 0;
        }
    }
}

.searchResult {
    border-top: 1px solid #1890FF;
    max-width: 700px;
    margin: 50px auto 0;
    text-align: left;
    padding-top: 50px;

    @media all and (max-width: 768px) {
        margin: 40px auto 0;
        padding-top: 40px;
    }

    &-VenueName {
        margin-top: 0;
        margin-bottom: 5px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #171717;

        @media all and (max-width: 768px) {
            font-size: 22px;
            line-height: 26px;
        }
    }

    &-VenueLocation {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #656565;

        @media all and (max-width: 768px) {
            font-size: 18px;
            line-height: 21px;
        }
    }

    &-qrPdf {
        display: inline-block;
        margin-top: 15px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #C2C2C2;
    }

    &-learnMore {
        margin-top: 30px;

        a {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #1890FF !important;
        }
    }

    &-windowTitle {
        display: inline-block;
        max-width: 600px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 30px;
        color: #171717;
        padding: 34px 26px 14px;

        @media all and (max-width: 768px) {
            font-size: 22px;
            line-height: 26px;
            padding: 34px 0 0;
        }

        &__close {
            position: absolute !important;
            top: 5px !important;
            right: 5px !important;
            color: #131416 !important;
        }
    }

    &-window {

        @media all and (max-width: 768px) {
            .MuiDialog-paper {
                margin: 15px !important;
            }
            .MuiDialogTitle-root {
                padding: 20px 15px;
            }
            .MuiDialogContent-root {
                padding: 8px 20px;
            }
        }
    }

    &-captcha {
        padding: 0 0 52px 26px !important;

        @media all and (max-width: 768px) {
        padding: 0!important;

        }
    }
}
