.statistic {
    margin: 30px auto 40px;
    width: 100%;

    &-title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #656565;
        margin-bottom: 15px;

    }

    &-noItems {
        margin-top: 10px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #171717;
    }

    &-table {
        text-align: center;
        width: 100%;
        border: 1px solid #171717;
        box-sizing: border-box;
        border-radius: 5px;
        border-collapse: initial;

        tr:nth-child(1) > td {
            border-bottom: 1px solid #171717;
        }
        tr:nth-child(1) > td:nth-child(1) {
            border-right: 1px solid #171717;
        }
        tr:nth-child(2) > td:nth-child(1) {
            border-right: 1px solid #171717;
        }

        td {
            width: 50%;
            vertical-align: middle;
        }

        &__period {
            margin: 10px 0;
            display: inline-block;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #171717;
        }

        &__value {
            margin: 15px 0;
            display: inline-block;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 700;;
            font-size: 32px;
            line-height: 37px;
            color: #1890FF;
        }
    }
}