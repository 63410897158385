.info {
    max-width: 1170px;
    width: 100%;
    height: fit-content;
    margin: 20px auto;

    &__itemDetails {
        width: 100%;
        display: flex;
        flex-direction: row;

        &-directMessage {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #1890FF;
            cursor: pointer;

            outline: none;

            &:hover {
                color: #127DDE;
            }

            img {
                margin-right: 10px;
            }
        }

        &-img {
            margin-right: 17px;
            background: #BFC3CD;
            border-radius: 100%;
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-info {

        }

        &-name {
            margin: 0;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #171717;
        }

        &-location {
            margin: 2px 0 0;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
            color: #656565;
        }
    }

    &__actions {
        width: 100%;
        margin: 30px 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-statistic {
            text-transform: inherit;

            .MuiToggleButtonGroup-root {
                border-radius: 8px;
                width: 520px;
            }

            .MuiToggleButton-root {
                border: none;
                background: white;
                padding: 0;
                width: calc(520px / 4);
                height: 50px;

                &:nth-child(4) {
                    .MuiTouchRipple-root {
                        border: none;
                    }
                }
            }

            .MuiTouchRipple-root {
                border: 1px solid #DEE5F5;

                &:nth-child(even) {
                    border: none;
                }

                &:nth-child(even) {
                    border-right: 1px solid #DEE5F5;
                }

            }

            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {border: 0}

            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {margin-left: 0;}

            .Mui-selected {
                border: 1px solid #76777b !important;
                border-right: 1px solid #838383 !important;
                background: #EFF1F5;

                .MuiToggleButton-label {
                    text-transform: initial;
                    font-family: Roboto, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #000000;
                    width: max-content;
                }
            }

            .MuiToggleButton-label {
                text-transform: initial;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #9F9F9F;
                width: max-content;
                pointer-events: none;
            }
        }
    }

    &__statistics {
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(44, 97, 154, 0.05);
        border-radius: 15px;

        &-data {
            border-bottom: 1px solid rgba(24, 144, 255, 0.5);
            padding: 20px;

            &-title {
                margin: 0 0 5px;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #000004;
            }

            &-period {
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                text-align: left;
                color: #6C6C6C;
            }

            &-attention {
                margin: 16px 0 30px;
                width: 577px;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #9F9F9F;
            }

            &-box {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }
        }

        &-item {
            position: relative;
            width: 303px;
            height: 100px;
            margin-right: 41px;
            background: rgba(249, 251, 255, 0.5);
            border: 0.5px solid rgba(130, 137, 156, 0.2);
            box-sizing: border-box;
            border-radius: 10px;
            padding: 7px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            &-title {
                top: 7px;
                position: absolute;
                margin: 0;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #82899C;
            }

            &-count {
                margin: 0;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: 900;
                font-size: 32px;
                line-height: 37px;
                color: #000004;
            }
        }

        &-loading {
            background: rgba(249, 251, 255, 0.5);
            animation-name: loadingBg;
            animation-duration: 2s;
            animation-iteration-count: infinite;

            .info__statistics-item-count {
                font-weight: 300;
            }
        }

        @keyframes loadingBg {
            0% {
                background-color: rgba(249, 251, 255, 0.5);
            }
            50% {
                background-color: #e7e9ef;
            }
            100% {
                background-color: rgba(249, 251, 255, 0.5);
            }
        }

        &-messages {
            padding: 30px 20px;
            background: #FBFBFB;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 0 0 15px 15px;

            &-title {
                margin: 0;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 26px;
                color: #000000;

            }

            &-text {
                margin-top: 10px;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #9F9F9F;
            }
        }

        &-messagesText {
            width: 577px;
        }
    }
}


.DateInput {
    //display: none;
}

.DateRangePickerInput_arrow {
    //display: none;
}