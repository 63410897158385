.modalQr {
    max-width: 700px;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    @media all and (max-width: 768px) {
        .MuiDialog-paper {
            margin: 15px !important;
        }
        .MuiDialogTitle-root {
            padding: 20px 15px;
        }
        .MuiDialogContent-root {
            padding: 8px 20px;
        }
    }

    &-title {
        margin-top: 0;
        margin-bottom: 10px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }

    &-text {
        margin-top: 0;
        margin-bottom: 28px;
        display: inline-block;
        max-width: 500px;
        width: 100%;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #9F9F9F;
    }

    &-venueInfo {
        display: flex;
        flex-direction: row;
    }

    &-venueInfoDetails {
        margin-left: 12px;
    }

    &-venueName {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #171717;
        margin: 0 0 5px;
    }

    &-venueLocation {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #656565;
        margin-bottom: 41px;
    }

    &-textOption {
        margin-top: 0;
        margin-bottom: 17px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #656565;
    }

    &-textOptionLink {
        margin-top: 25px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #656565;
    }

    &-checkbox {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #474747;
        mix-blend-mode: normal;
    }

    &-generateQrButton {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }

    &-generateQrInfo{
        margin-top: 10px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #C2C2C2;
    }





    .Mui-checked {
        color: #656565!important;
    }
}