.date-picker {
    &__bottom-info {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #DEE5F5;
        padding: 20px;

        span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }
    }

    .DayPickerKeyboardShortcuts_show__bottomRight {
        display: none;
    }

    .DayPickerNavigation_svg__horizontal {
        fill: transparent;
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../static/img/arrow.svg');
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../static/img/arrow.svg');
        transform: rotate(180deg);
    }

    .CalendarDay {
        vertical-align: middle;
        border: none;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.016em;

        width: 375px;
        height: 342px;
    }

    .CalendarDay__default:hover {
        border: none;
    }

    .CalendarDay__selected {
        background: #1890FF;
        border: none;
        color: #fff;
        border-radius: 5px;
    }

    .CalendarDay__selected:hover {
        background: #1890FF;
    }

    .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover, .CalendarDay__selected_span {
        background: #e8f4ff;
        color: #78819B;
    }

    .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
        background-color: #e8f4ff;
        color: #78819B;
    }

    .CalendarMonth_caption {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.016em;

        border-top: 1px solid #1890FF;
    }

    .CalendarDay__outside {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.016em;
        color: #78819B;
        opacity: 0.3;
    }

    .DayPicker_weekHeader_ul {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.016em;
    }

    .DayPickerNavigation_button__default {
        background-color: #1890FF;
        border: none;
    }

    .DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
        border: none;
        background-color: #1890FF;
        outline: none;
    }

    .DateInput_fang, .DateInput_fang_1 {
        display: none;
    }

    .DateRangePicker_picker__directionLeft {
        top: 53px !important;
        left: -95px !important;
    }

    .CalendarMonth_caption {
        margin-left: -22px;
        margin-right: -23px;
        padding-bottom: 50px;
        background-color: #1890FF;

        strong {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.016em;
            color: #FFFFFF;
        }
    }

    .DayPicker_weekHeader_li {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.016em;

        color: #FFFFFF;
    }
}
