.advancedSearch {
    max-width: 1170px;
    width: 100%;
    height: fit-content;
    margin: 20px auto 0;
    border-radius: 16px;
    background: #E7E9EF;

    &-title {
        margin: 25px auto 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #000000;
    }

    &-text {
        max-width: 577px;
        margin: 10px auto 25px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #9F9F9F;
    }

    &-box {
        max-width: 586px;
        width: 100%;
        margin: 0 auto;


        &__activeTab-1 {
            .MuiBox-root {
                border-radius: 0 0 8px 8px !important;
            }
        }

        &__activeTab-2 {
            .MuiBox-root {
                border-radius: 0 0 8px 8px !important;
            }
        }

        &__activeLabel-0 {
            overflow: visible;
            position: relative;
            z-index: 1;

            .MuiTab-wrapper {
                &:after {
                    content: '';
                    position: absolute;
                    right: -10px;
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: #FFFFFF;
                    z-index: 2;
                    bottom: 0;
                }

            }
        }

        &__activeLabel-1 {
            overflow: visible;
            position: relative;
            z-index: 1;

            .MuiTab-wrapper {
                &:after {
                    content: '';
                    position: absolute;
                    right: -10px;
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: #FFFFFF;
                    z-index: 2;
                    bottom: 0;
                }

                &:before {
                    left: -10px;
                    content: '';
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: #FFFFFF;
                    z-index: 2;
                    bottom: 0;
                }
            }
        }

        &__activeLabel-2 {
            overflow: visible;
            position: relative;
            z-index: 1;

            .MuiTab-wrapper {
                &:before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: #FFFFFF;
                    z-index: 2;
                    bottom: 0;
                }

            }
        }

        &__activeLabel-0.Mui-selected {
            overflow: visible;
            position: relative;
            z-index: 2;

            &:after {
                content: '';
                position: absolute;
                right: -20px;
                display: block;
                width: 20px;
                height: 20px;
                background: #E7E9EF;
                z-index: 3;
                bottom: 0;
                border-radius: 10px;
            }
        }

        &__activeLabel-1.Mui-selected {
            overflow: visible;
            position: relative;
            z-index: 2;

            &:after {
                content: '';
                position: absolute;
                right: -20px;
                display: block;
                width: 20px;
                height: 20px;
                background: #E7E9EF;
                z-index: 3;
                bottom: 0;
                border-radius: 10px;
            }

            &:before {
                left: -20px;
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                background: #E7E9EF;
                z-index: 3;
                bottom: 0;
                position: absolute;
                border-radius: 10px;
            }

        }

        &__activeLabel-2.Mui-selected {
            overflow: visible;
            position: relative;
            z-index: 2;

            &:before {
                content: '';
                position: absolute;
                left: -20px;
                display: block;
                width: 20px;
                height: 20px;
                background: #E7E9EF;
                z-index: 3;
                bottom: 0;
                border-radius: 10px;
            }
        }
    }

    &__location {

        &-addCity {
            margin-top: 15px;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #171717;
            cursor: pointer;
            outline: none;
        }
    }

    &__user {
        display: flex;
        flex-direction: column;

        &-or {
            margin: 10px 0;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #C9C9C9;

        }
    }
}

