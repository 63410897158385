.geographical {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(44, 97, 154, 0.05);
  padding: 25px 30px;

  &-title {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000004;
  }

  &-subTitle {
    margin: 0;
    margin-top: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #9F9F9F;
  }

  &-container {
    margin-top: 27px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      background: #EFF1F5;
      border-radius: 5px;
      padding: 15px 25px;
    }

    &__item {
      width: 30%;

      &:first-child {
          width: 70%;
        }

      &:last-child {
        width: 20%;
      }

      &-count {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #131416;
      }

      &-title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #131416;
      }
    }

    &__block {
      display: flex;
      flex-direction: row;
      padding: 15px 25px;
      border-bottom: 1px solid rgba(0,0,0, 0.1);

      &:last-child{
        border-bottom: none;
      }


    }

    &__headerTitle {
      margin: 0;
      width: auto;
      font-family: Roboto, sans-serif ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #5F6871;
    }
  }
}