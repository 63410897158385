.footer {
    max-width: 1360px;
    width: 100%;
    min-height: 95px;
    margin: 90px auto 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #1890FF;

    @media (min-width: 0) and (max-width: 1366px) {
        padding: 0 15px;
    }
    @media (min-width: 0) and (max-width: 768px) {
        flex-direction: column;
        margin: 50px 0 auto;
    }

    &-logo {
        @media (min-width: 0) and (max-width: 768px) {
          padding: 20px 0;
        }

    }

    &-nav {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @media (min-width: 0) and (max-width: 768px) {
            padding: 0 0 20px;
        }

        @media (min-width: 320px) and (max-width: 420px) {
            flex-direction: column;
        }

        &__link {
            margin-left: 50px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #171717;
            @media (min-width: 320px) and (max-width: 420px) {
               margin: 0;
            }

            &:nth-child(1){
                margin-left: 0;
            }

            &:nth-child(2){
                @media (min-width: 320px) and (max-width: 420px) {
                    margin: 20px 0 10px;
                }
            }
        }

        &__copy {
            margin-left: 50px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #656565;

            @media (min-width: 0) and (max-width: 425px) {
                width: 100%;
                margin: 20px auto 0;
            }
        }
    }
}