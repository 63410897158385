.searchForm {
margin-bottom: 30px;
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

        input {
            width: 250px;
            max-width: 400px;
            padding: 10px;
            margin: 10px;
        }

        button {
            outline: none;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                background-color: #127DDE;
                transition: 0.3s;
            }
        }
    }
}