.homeMain {
    width: 1360px;
    margin: 70px auto 0;

    @media (min-width: 320px) and (max-width: 1360px) {
        width: 100%;
    }

    &-headLine {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 55px;
        line-height: 64px;
        text-align: center;
        color: #171717;

        @media (min-width: 320px) and (max-width: 768px) {
            font-size: 38px;
            line-height: 45px;
            margin-bottom: 0;
        }

    }

    &-blocks {

        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        &__os {
            display: flex;
            flex-direction: row;
        }

        &__item {
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 500px;


            @media (min-width: 320px) and (max-width: 1000px) {
                margin: 80px auto 0;

                &:nth-child(1) {
                    margin-top: 30px
                }
            }

            @media (min-width: 0) and (max-width: 512px) {
                padding: 0 15px;
            }

        }

        &__img {

            height: 386px;

            @media (min-width: 320px) and (max-width: 768px) {
                width: calc(100% - 30px);
                height: auto;
                margin: 0 auto;
            }
        }

        &__title {
            margin: 40px auto 10px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: #171717;

            @media (min-width: 320px) and (max-width: 768px) {
                margin: 30px auto 10px;
            }
        }

        &__subtitle {
            margin: 0;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            text-align: center;
            color: #171717;

            @media all and (max-width: 768px) {
                margin-bottom: 16px;
            }
        }

        &__paragraph {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;

            color: #82899C;
            @media (min-width: 320px) and (max-width: 768px) {
                margin: 0 auto 10px;
            }
        }
    }
}

.whyOver {
    width: 1360px;
    margin: 140px auto 0;
    font-family: Roboto, sans-serif;

    @media (min-width: 320px) and (max-width: 1360px) {
        width: 100%;
        margin: 100px auto 0;
    }

    &-headLine {
        max-width: 671px;
        margin: 0 auto;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 50px;
        line-height: 59px;
        text-align: center;
        color: #171717;

        @media (min-width: 320px) and (max-width: 768px) {
            font-size: 38px;
            line-height: 45px;
            margin-bottom: 0;
            padding: 0 15px;
        }
    }

    &-blocks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media (min-width: 576px) and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 320px) and (max-width: 575px) {
            grid-template-columns: repeat(1, 1fr);
        }

        &__item {
            max-width: 300px;
            text-align: center;
            margin: 70px auto 0;

            &-img {
                margin: 0 auto;
            }

            h4 {
                font-family: Roboto, sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #171717;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 10px;
            }

            p {
                margin: 0;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: center;

                color: #82899C;
            }
        }
    }
}