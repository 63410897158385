.sendMessage {
    width: 700px;
    margin: 0 auto;

    &-content {
        width: 600px!important;
        margin: 0 auto 40px;
    }

    &-title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }

    &-subTitle {
        width: 499px;
        margin: 10px auto 40px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #9F9F9F;
    }

    &-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        &-title {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #9F9F9F;
            margin: 0;
        }

        &-subTitle {
            margin-top: 5px;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
        }

        &-info {
            width: calc(100% / 3);
            display: flex;
            justify-content: flex-start;
        }

        &-count {
            width: calc(100% / 3);
            display: flex;
            justify-content: flex-start;
        }

        &-period {
            width: calc(100% / 3);
            display: flex;
            justify-content: flex-start;

        }

        &-img {
            margin-right: 15px;
            width: 42px;
            height: 42px;
            background: rgba(196, 196, 196, .2);
            border-radius: 100%;
            display: flex;
            align-items: center;

            img {
                width: 18px;
                margin: 0 auto;
            }
        }
    }
    &-form {
        margin: 0 auto;
        width: 100%;
        flex-direction: column;
        display: flex;

        &:first-child {
            margin: 0!important;
        }

        &-input {
            margin-top: 22px!important;
        }
    }
}