.jumbotron {
    z-index: 1;
    margin-top: -100px;
    width: 100%;
    height: auto;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../static/img/blueBg.svg');

    @media (min-width: 0) and (max-width: 768px) {
        margin-top: -70px;
        height: max-content;
        overflow-x: hidden;
    }

    @media all and (max-width: 1025px) {
        background-image: url('../../../static/img/blueBg.svg');
        background-position: bottom;
    }

    &-action {
        z-index: 999999999;
        max-width: 1360px;
        height: 100vh;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin: 0 auto;

        &__phones {
            @media all and (max-width: 1024px) {
                display: none;
            }
            @media (min-width: 1024px) and (max-width: 1070px) {
                right: 0;
                top: 159px;

                &-img {
                    height: 55vh;
                }
            }
            @media (min-width: 1070px) and (max-width: 1292px) {
                top: 100px;

                &-img {
                    height: 70vh;
                }
            }

            position: absolute;
            top: 0;
            z-index: -1;
            right: 0;
            height: 100vh;

            &-img {
                height: 100vh;

                @media (min-width: 1000px) and (max-width: 1070px) {
                    height: 55vh;
                }
                @media (min-width: 1070px) and (max-width: 1292px) {
                    height: 70vh;
                }
            }
        }

        @media (min-width: 0) and (max-width: 768px) {
            height: 80vh;
        }
        @media (min-width: 0) and (max-width: 1440px) {
            padding: 0 15px 0;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
            margin-top: 50px
        }

        @media screen and (orientation: landscape) and (max-width: 850px) {
            margin-top: 65px !important;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
            margin-top: 50px !important;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
            margin-top: 100px;
            align-items: flex-start;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
            margin-top: 80px;
        }
    }

    &-text {
        max-width: 525px;

        @media (min-width: 320px) and (max-width: 1025px) {
            margin: 0 auto;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
            max-width: 90%
        }

        &__head {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 70px;
            color: #ffffff;
            margin-bottom: 30px;

            //@media all and (max-width: 346px) {font-size: 25px !important;}

            @media (min-width: 320px) and (max-width: 768px) {
                font-size: 38px;
                line-height: 45px;
                text-align: center;
            }

            @media screen and (orientation: landscape) and (max-width: 850px) {
                font-size: 30px;
                margin-bottom: 0;
            }

            @media (orientation: landscape) and (min-width: 851px) and (max-width: 896px) {
                font-size: 30px;
                margin-bottom: 0;
            }
        }

        &__paragraph {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #F4F4F4;
            margin-bottom: 70px;

            @media (min-width: 320px) and (max-width: 768px) {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 50px;
                text-align: center;
            }

            @media all and (max-width: 474px) {
                margin-bottom: 20px;
            }

            @media screen and (orientation: landscape) and (max-width: 850px) {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 20px;
            }

            @media (orientation: landscape) and (min-width: 851px) and (max-width: 896px) {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }

    }

    &-download {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (min-width: 0) and (max-width: 768px) {
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        @media (min-width: 0) and (max-width: 512px) {
            justify-content: center;
        }
        @media all and (max-width: 474px) {
            justify-content: space-around;
        }

        &__img {
            margin-right: 40px;
            @media (min-width: 0) and (max-width: 511px) {
                margin: 0;
            }

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        &__svg {
            @media all and (max-width: 551px) {
                width: 180px;
            }
        }
    }

    &-phones {
        display: none;

        @media (min-width: 0) and (max-width: 768px) {
            display: block;
            z-index: 1;
        }

        &__img {
            width: 193%;
            margin: -140px -50% 0;
            @media only screen and (min-device-width: 1023px) and (max-device-width: 1025px) {
                width: 186%;
                margin: -638px -50% 0;
            }
            @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
                margin: -270px -50% 0;
            }
            @media only screen and (min-device-width: 414px) and (max-device-width: 667px) and (orientation: portrait) {
                margin: -300px -50% 0;
            }
            @media only screen and (min-device-width: 414px) and (max-device-width: 667px) and (orientation: landscape) {
                margin: -140px -50% 0;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 374px) and (orientation: portrait) {
                margin: -35px -50% 0;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 374px) and (orientation: landscape) {
                margin: -100px -50% 0;
            }
        }
    }
}