.form-header {
  width: 100%;
  max-width: 1360px;
  margin: 50px auto 0;
    padding: 0 15px;
  text-align: center;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #171717;
  }
}